import { findBook } from "../useBible";
import { toAppVerseModel } from "../useBible/bibles.models";
import {
    loadPlanPassage,
    loading,
} from "./bibleplans.api";
import { biblePlansColl } from "./bibleplans.constants";

// const planModel = ref<any>({});

export function useBiblePlans() {

    async function fetchBiblePlanPassage({ planBibleCode, planSlug, planDay }: any) {

        const { passage } = await loadPlanPassage({ planBibleCode, planSlug, planDay }) || {};
        if (!passage) return;

        const versesModelsColl = passage.map((verse: any) => {
            const book = findBook({
                bibleCode: planBibleCode,
                bookCode: verse.book_code
            });
            return toAppVerseModel({ verse, book })
        }) || [];

        return versesModelsColl;
    }

    return {
        biblePlansColl,
        fetchBiblePlanPassage,
        loading
    }
}