import { useAxios } from '@/composables/useAxios'
import { NUXT_APP_BIBLE_API } from '~/env';

const { axios, loading } = useAxios();

const yourbibleApi = NUXT_APP_BIBLE_API;

export async function _create(planModel: any) {
    const { today } = await axios.post(yourbibleApi + `/todays/create`, planModel);
    return today;
}

export async function _remove(_id: any) {
    const { success } = await axios.delete(yourbibleApi + `/todays/remove/` + _id);
    return success;
}

export async function loadPlanPassage({ planBibleCode, planSlug, planDay }: any) {
    const passageModel = await axios.get(yourbibleApi + `/planspassage/single/${planBibleCode}/${planSlug}/${planDay}`);
    return passageModel;
}

export {
    loading,
}